
function viewImages(imagepath, imageName) {
  // console.log(imagepath.id, imageName);
  let bodyMaine = document.body
  bodyMaine.classList.add('active')
  let imagebox =  document.getElementById('imgBoxgal')
 let imgb = document.createElement("img")
 imgb.classList.add('img-fluid')
 imgb.src = imagepath.id
 imagebox.appendChild(imgb)
 let imgtext = document.getElementById('imageText')
 imgtext.innerHTML = imageName
}

document.addEventListener('keydown', function(e) {
  if(e.key === "Escape") {
    // console.log(e.key);
    closeGalery()
  }
});
function closeGalery(){
    let bodyMaine = document.body
    bodyMaine.classList.remove('active') 
    let imagebox =  document.getElementById('imgBoxgal')
    imagebox.innerHTML = ''
    let imgtext = document.getElementById('imageText')
    imgtext.innerHTML = ''
}

